// import React from "react";
import React, { useState, useEffect } from "react";
import "./CSS/PhotoGalleryPage.css";
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import image4 from "../images/image4.jpg";
import image5 from "../images/image5.jpg";
import image6 from "../images/image6.jpg";
import image7 from "../images/image7.jpg";
import image8 from "../images/image8.jpg";
import image9 from "../images/image9.jpg";
import image10 from "../images/image10.jpg";
import image11 from "../images/image11.jpg";
import image12 from "../images/image12.jpg";
import image13 from "../images/image13.jpg";
import image14 from "../images/image14.jpg";
import image15 from "../images/image15.jpg";
import image16 from "../images/image16.jpg";
import image17 from "../images/image17.jpg";
import image18 from "../images/image18.jpg";
import image19 from "../images/image19.jpg";
import image20 from "../images/image20.jpg";
import image21 from "../images/image21.jpg";
import image22 from "../images/image22.jpg";
import image23 from "../images/image23.jpg";
import image24 from "../images/image24.jpg";
import image25 from "../images/image25.jpg";
import image26 from "../images/image26.jpg";
import image27 from "../images/image27.jpg";
import image28 from "../images/image28.jpg";
import image29 from "../images/image29.jpg";
import image30 from "../images/image30.jpg";
import image31 from "../images/image31.jpg";
import image32 from "../images/image32.jpg";


const PhotoGalleryPage = () => {
  const category1Images = [
    { front: image1, back: image2 },
    { front: image3, back: image4 },
    { front: image5, back: image6 },
    { front: image8, back: image7 },
    { front: image9, back: image10 },
    { front: image11, back: image12 },
    { front: image14, back: image19 },
    { front: image18, back: image17 },
  ];
  const category2Images = [
    { front: image13, back: image15 },
    { front: image16, back: image20 },
    { front: image21, back: image22 },
    { front: image23, back: image25 },
    { front: image24, back: image28 },
    { front: image27, back: image30 },
    { front: image26, back: image31 },
    { front: image29, back: image32 },
  ];

  const [galleryData, setGalleryData] = useState([
    // ... your existing image data ...
    // Example Facebook videos:
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/1348529752480590",
    },
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/954158429584741",
    },
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/350398938055270",
    },
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/1348529752480590",
    },
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/350398938055270",
    },
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/760209479630666",
    },
    {
      type: "facebook-video",
      facebookLink:
        "https://www.facebook.com/61550964583201/videos/956436922640839",
    },
  ]);

  useEffect(() => {
    // Initialize the Facebook SDK (do this only once the component mounts)
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "<YOUR_FACEBOOK_APP_ID>", // Replace with your ID
        xfbml: true,
        version: "v14.0", // Or your desired version
      });
    };

    // Load the Facebook SDK script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <section id="gallery" className="pho-gallery-page">
      <div className="pho-container">
        <h2>MEDIA</h2>

        <div className="pho-gallery-category">
          <div className="pho-gallery-grid">
            {category1Images.map((image, index) => (
              <div key={index} className="pho-gallery-item">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={image.front}
                        alt={`Category 1 Photo ${index + 1}`}
                      />
                    </div>
                    <div className="flip-card-back">
                      <img
                        src={image.back}
                        alt={`Category 1 Photo ${index + 1} Back`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="pho-gallery-category">
          <div className="pho-gallery-grid">
            {category2Images.map((image, index) => (
              <div key={index} className="pho-gallery-item">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={image.front}
                        alt={`Category 2 Photo ${index + 1}`}
                      />
                    </div>
                    <div className="flip-card-back">
                      <img
                        src={image.back}
                        alt={`Category 2 Photo ${index + 1} Back`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="video-gallery-category">
          <h3>Facebook Videos</h3>
          <div className="video-gallery-grid">
            {galleryData.map((item, index) => (
              <div key={index} className="video-gallery-item">
                {item.type === "facebook-video" && (
                  <div
                    className="fb-video"
                    data-href={item.facebookLink}
                    data-width="500"
                  ></div>
                )}
                {/* Other item types remain as they were  */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PhotoGalleryPage;
