import React from 'react';
import './CSS/PhotoGallery.css'; 
import image11 from '../images/image11.jpg'; 
import image21 from '../images/image21.jpg'; 
import image27 from '../images/image27.jpg'; 
import image13 from '../images/image13.jpg'; 
import image24 from '../images/image24.jpg'; 
import image12 from '../images/image12.jpg'; 
import image16 from '../images/image16.jpg'; 
import image32 from '../images/image32.jpg'; 
import image9 from '../images/image9.jpg'; 
import image10 from '../images/image10.jpg'; 


const PhotoGallery = () => {
  // Array of image URLs
  const images = [image11, image21, image27,image13, image24,image12, image16, image32, image9, image10]; // Add more images to the array

  return (
    <section id="gallery" className="pic-gallery">
      <div className="pic-container">
        <h2>Photo Gallery</h2>
        <div className="pic-gallery-grid">
          {images.map((image, index) => (
            <div key={index} className="pic-gallery-item">
              <img src={image} alt={`Photo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PhotoGallery;
