import React from "react";
import "./CSS/AboutUsPage.css";
import NavaRathanalu from "../images/nava.jpg";

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <div className="about-container">
      <h1>నవరత్నాలు</h1>
        <img className="main-image" src={NavaRathanalu} alt="nava-rathnalu" />
      </div>
    </div>
  );
};

export default AboutUsPage;
