import React from "react";
import logow5 from "../LOGOS/logow5.png";
import "./CSS/PalakolluPage.css";

const PalakolluPage = () => {
  return (
    <div className="container">
      <div className="img-container">
        <img src={logow5} alt="Gopi" />
      </div>
      <div className="paragraph-box">
        <div className="paragraph">
          <p>పాలకొల్లు నియోజకవర్గం ప్రజలారా</p>
          <p>నేను మీ గుడాల గోపి</p>
          <p> మీ సంతోషంలో.. మీ సమస్యల్లో</p>
          <p>మీ వెంట నడిచే మీ వాడిని..</p>
        </div>

        <div className="paragraph">
          <p>దివంగత ముఖ్యమంత్రి స్వర్గీయ</p>
          <p>వై యస్ రాజశేఖర్ రెడ్డి గారి ఆశయాలతో</p>
          <p>మన జగనన్న అమలు చేస్తున్న</p>
          <p>ప్రభుత్వ సంక్షేమ పథకాలన్నిటినీ</p>
          <p>మీ ఇంటి వరకూ చేర్చే బాధ్యతలో</p>
          <p>మనసా వాచా కర్మణా నిబద్ధతతో</p>
          <p>పనిచేసే మీ కుటుంబ సభ్యుడిని..</p>
        </div>

        <div className="paragraph">
          <p>నాకు ఎన్నికల సమయంలోనే రాజకీయాలు..</p>
          <p>మిగతా రోజుల్లో మనమంతా ఒకే కుటుంబం.</p>
        </div>

        <div className="paragraph">
          <p>మన ప్రియతమ ముఖ్యమంత్రి జగనన్న</p>
          <p>
            నాపై ఎంతో నమ్మకంతో మన పాలకొల్లు అసెంబ్లీ నియోజకవర్గాన్ని
            అప్పగించారు.
          </p>
          <p>ఇక్కడ నేను YSRCP తరపున</p>
          <p>ఎమ్మెల్యే గా పోటీ చేస్తున్నాను.</p>
          <p>నన్ను ఆదరించి,</p>
          <p>రానున్న ఎన్నికల్లో ఫ్యాన్ గుర్తుపై ఓటు వేసి </p>
          <p>ఒక్క అవకాశం ఇవ్వాలని కోరుతూ</p>
          <p>సదా మీ సేవలో..</p>
          <p>మీకు హృదయ పూర్వక కృతజ్ఞతలతో..</p>
          <p className="gopi">మీ గుడాల గోపి... </p>
        </div>
      </div>
    </div>
  );
};

export default PalakolluPage;
