import React from 'react'
import Banner from '../Components/Banner'
import PhotoGallery from '../Components/PhotoGallery'
import ContactPage from "../Pages/ContactPage"

const Homepage = () => {
  return (
    <>
        <Banner/>
        <PhotoGallery/>
        <ContactPage/>
    </>
  )
}

export default Homepage