import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/header";
import Homepage from "./Pages/HomePage.jsx";
import Footer from "./Components/Footer";
import AboutUsPage from "./Pages/AboutUsPage.jsx";
import PhotoGalleryPage from "./Pages/PhotoGalleryPage.jsx";
import ContactPage from "./Pages/ContactPage.jsx";
import PalakolluPage from "./Pages/PalakolluPage.jsx";


const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/నవరత్నాలు" element={<AboutUsPage />} />
        <Route path="/media" element={<PhotoGalleryPage />} />
        <Route path="/పాలకొల్లు-నియోజకవర్గం" element={<PalakolluPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
