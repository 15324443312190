import React from "react";
import "./CSS/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="foot">
        <p>&copy; 2024, gopigudala.com  All Rights Reserved</p>
        <p>Built and Developed by <a href="https://www.4sdigilabs.com" target="_blank" rel="noopener noreferrer">www.4sdigilabs.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
