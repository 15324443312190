import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CSS/header.css';

const Header = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleNavigation = (sectionId) => {
    navigate(`/${sectionId}`);
    setShowMenu(false); // Close the menu after navigation
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="header">
      <h1 className="logo" onClick={() => handleNavigation('')}></h1>
      <nav className="nav">
        <button className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`nav-list ${showMenu ? 'show' : ''}`}>
          <li><button className="nav-item" onClick={() => handleNavigation('నవరత్నాలు')}>నవరత్నాలు</button></li>
          <li><button className="nav-item" onClick={() => handleNavigation('పాలకొల్లు-నియోజకవర్గం')}>పాలకొల్లు నియోజకవర్గం</button></li>
          <li><button className="nav-item" onClick={() => handleNavigation('media')}>MEDIA</button></li>
          <li><button className="nav-item" onClick={() => handleNavigation('contact')}>CONTACT</button></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
